/*
 * @Date: 2023-02-12 13:09:13
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-12 14:08:11
 * @FilePath: \F1-M2-QRP-CODE\actions\API.js
 */
import HostConfig from '$CONFIG/Host.config'
export const CMSURL = HostConfig.Config.CMSURL;
export const HostApi = HostConfig.Config.HostApi;


export const ApiPort = {
	Banner1 : CMSURL + `/cms/qrp-m2-001-banners`,
	Banner2 : CMSURL + `/cms/qrp-m2-002-banners`,
	Banner3 : CMSURL + `/cms/qrp-m2-003-banners`,
	Banner4 : CMSURL + `/cms/qrp-m2-004-banners`,
	Banner5 : CMSURL + `/cms/qrp-m2-005-banners`,
	Banner6 : CMSURL + `/cms/qrp-m2-006-banners`,
	Banner7 : CMSURL + `/cms/qrp-m2-007-banners`,

	// 注册API
	Register: HostApi + `/api/QRP/Register`,
	// 登录
	Login : HostApi + `/api/QRP/Login` ,
	//
	QrpLoad : HostApi + '/api/QRP/URLs' ,
}