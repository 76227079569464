/*
 * @Date: 2023-01-30 10:48:36
 * @LastEditors: simLarry
 * @LastEditTime: 2023-02-23 13:44:42
 * @FilePath: \F1-M2-QRP-CODE\components\hayhar\socialbtn.js
 */
import React from "react";


class Socialbtn extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			social_list : [
				{
					icon: '/static/images/hayhar/icon-customer.png',
					text: 'Live Chat',
					href: props.liveChatUrl || 'https://cranberryapp.hihi2u.com/app',
					gtag: ()=>{ global.globalGtag && globalGtag('Style1_QRP','Side nav bar','CS_sidenav' , 'event')}
				},{
					icon: '/static/images/hayhar/icon-line.png',
					text: 'Line',
					href: 'https://lin.ee/waGfQKv',
					gtag: ()=>{ global.globalGtag && globalGtag('Style1_QRP','Side nav bar','Line_sidenav','event')}

				}
			]
		}
	}

	gtagPiwik(fn){
		fn();
	}

	render(){
		let { social_list } = this.state;
		return (
			<>
				{/* social btns */}
				<div className={'social-web'}>
					{
						social_list.map((item , key) =>{
							return (
								<div key={item.text}>
									{item.text === 'Live Chat' ?
										<div className={'social-item'} onClick={()=>{global.globalGtag && global.globalGtag('Style1_QRP','Side nav bar' , 'CS_sidenav' ,'event'); window.open(item.href, "Live Chat", "width=600,height=600")} }>
											<img src={item.icon} />
											<span>{item.text}</span>
										</div>
									:
										<a key={key} href={item.href} className={'social-item'} target="_blank"  onClick={()=>{this.gtagPiwik(item.gtag)}}>
											<img src={item.icon} />
											<span>{item.text}</span>
										</a>
									}
								</div>
							)
						})
					}
				</div>
			</>
		)
	}
}

export default Socialbtn;
